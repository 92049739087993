<template>
  <div class="wr print-page-component" style="background: #ffffff; height: 100%">
    <v-dialog v-model="show_email_dialog" width="600" persistent class="non-printable">
      <v-card>
        <v-card-text class="pt-4">
          <v-alert type="success" outlined rounded>
            Вкажіть email отримувача. По замовчуванні підставляється email адреса власника помешкання
          </v-alert>
          <v-form v-model="formValid" ref="form">
            <v-text-field
                v-model="email"
                label="Email"
                hide-details
                filled
                required
                color="grey"
                :rules="emailRules"
                :class="email ? '' : 'req-start'"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed text color="secondary darken-1" @click.stop="email_dialog_send"
                 :loading="generate_email"
                 class="button-accept">
            <v-icon left>mdi-content-save</v-icon>
            Надіслати
          </v-btn>
          <v-btn depressed text tile color="grey" @click="email_dialog_close">
            <v-icon left>mdi-close</v-icon>
            Відмінити
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-toolbar color="grey lighten-4" elevation="1" class="non-printable" :height="getHeight">
      <template v-if="$vuetify.breakpoint.xs">
        <div style="width: 100%">
          <div class="d-flex mb-3">
            <v-btn
                class="grey darken-2"
                depressed
                dark
                @click.stop="closeModal"
                style="flex: 1 1 20%"
            >
              <v-icon left size="28" class="mr-2">mdi-close</v-icon>
            </v-btn>
            <v-btn
                class="grey lighten-3 mr-1"
                outlined
                @click.stop="openSettings"
                style="flex: 1 1 20%"
            >
              <v-icon color="teal">mdi-filter-menu-outline</v-icon>
            </v-btn>
            <v-btn
                class="grey lighten-3 mr-1"
                outlined
                @click.stop="print"
                style="flex: 1 1 20%"
            >
              <v-icon color="primary">mdi-printer</v-icon>
            </v-btn>
            <v-btn
                v-if="email_send_button"
                class="grey lighten-3 mr-2"
                outlined
                @click.stop="showStartSendingQuestion"
                :loading="email_status"
                style="flex: 1 1 20%"
            >
              <v-icon color="secondary">mdi-email</v-icon>
            </v-btn>
            <v-btn
                class="grey lighten-3"
                outlined
                @click.stop="generate_xlsx = true"
                :loading="xlsx_loading_status"
                style="flex: 1 1 20%"
            >
              <v-icon color="success">mdi-file-excel-outline</v-icon>
            </v-btn>
          </div>
          <div class="d-flex">
            <v-select
                :items="person_select"
                v-model="person"
                hide-details
                color="grey"
                style="flex: 1"
            />
          </div>
        </div>
      </template>
      <template v-if="$vuetify.breakpoint.sm">
        <v-btn
            class="grey lighten-3 mr-2"
            depressed
            @click.stop="closeModal"
            width="50"
        >
          <v-icon class="mr-2" color="primary">mdi-close</v-icon>
        </v-btn>
        <v-select
            :items="person_select"
            v-model="person"
            class="mx-3"
            hide-details
            color="grey"
            style="flex: 0 0 400px"
        />
        <v-spacer/>
        <v-btn
            class="grey lighten-3 mr-2"
            icon
            outlined
            @click.stop="openSettings"
        >
          <v-icon color="teal">mdi-filter-menu-outline</v-icon>
        </v-btn>
        <v-btn
            class="grey lighten-3 mr-2"
            icon
            outlined
            @click.stop="print"
        >
          <v-icon color="primary">mdi-printer</v-icon>
        </v-btn>
        <v-btn
            v-if="email_send_button"
            class="grey lighten-3 mr-2"
            icon
            outlined
            @click.stop="showStartSendingQuestion"
            :loading="email_status"
        >
          <v-icon color="secondary">mdi-email</v-icon>
        </v-btn>
        <v-btn
            class="grey lighten-3"
            icon
            outlined
            @click.stop="generate_xlsx = true"
            :loading="xlsx_loading_status"
        >
          <v-icon color="success">mdi-file-excel-outline</v-icon>
        </v-btn>
      </template>
      <template v-if="$vuetify.breakpoint.mdAndUp">
        <v-btn
            class="grey lighten-3 mr-2"
            depressed
            @click.stop="closeModal"
        >
          <v-icon left class="mr-2" color="primary">mdi-close</v-icon>
          Закрити
        </v-btn>
        <v-select
            :items="person_select"
            v-model="person"
            class="mx-3"
            hide-details
            color="grey"
            style="flex: 0 0 400px"
        />
        <v-spacer/>
        <v-btn
            class="grey lighten-3 mr-2"
            icon
            outlined
            @click.stop="openSettings"
        >
          <v-icon color="teal">mdi-filter-menu-outline</v-icon>
        </v-btn>
        <v-btn
            class="grey lighten-3 mr-2"
            icon
            outlined
            @click.stop="print"
        >
          <v-icon color="primary">mdi-printer</v-icon>
        </v-btn>
        <v-btn
            v-if="email_send_button"
            class="grey lighten-3 mr-2"
            icon
            outlined
            @click.stop="showStartSendingQuestion"
            :loading="email_status"
        >
          <v-icon color="secondary">mdi-email</v-icon>
        </v-btn>
        <v-btn
            class="grey lighten-3"
            icon
            outlined
            :loading="xlsx_loading_status"
            @click.stop="generate_xlsx = true"
        >
          <v-icon color="success">mdi-file-excel-outline</v-icon>
        </v-btn>
      </template>
    </v-toolbar>
    <div class="column-wrapper" style="display: block">
      <v-row no-gutters>
        <v-col cols="12" sm="4" md="3" class="non-printable" style="height: 100%">
          <v-list dense class="py-0">
            <v-subheader class="subtitle-1">Перелік довідок</v-subheader>
            <v-list-item-group
                v-model="current_certificate"
                color="grey darken-3"
            >
              <v-list-item
                  v-for="(item, index) in items"
                  :key="index"
                  style="border-bottom: 1px solid #cdcdcd"
                  :style="index === 0 ? 'border-top: 1px solid #cdcdcd' : ''"
                  @click.stop=""
              >
                <v-list-item-icon>
                  <v-icon size="24" color="success">
                    mdi-newspaper-variant-outline
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content class="subtitle-2" style="font-weight: 400 !important;">
                  {{ item }}
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
        <v-col cols="12" sm="8" md="9">
          <div class="wrapper-page">
            <div class="page page-a4 printable" id="print" ref="document"
                 :style="scale !== 100 ? `zoom: ${scale}%;` : ''"
            >
              <component
                  :is="getComponent"
                  :persons="persons"
                  :person="person"
                  :organization="organization"
                  :print_data="print_data"
                  :global_settings="settings"
                  :scale="scale"
                  :generate_xlsx="generate_xlsx"
                  :generate_email="generate_email"
                  :email_address="email"
                  @onGenerateXlsx="onGenerateXlsx"
                  @onGenerateEmail="onGenerateEmail"
                  @xlsx_loading_status="setXlsxLoading"
                  @email_loading_status="setSendingEmailStatus"
                  @closeSettings="closeSettings"
              ></component>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {FETCH_FAMILY_BY_FLAT} from "@/store/actions/family";
import {GET_ORGANIZATION_PRINT_DATA} from "@/store/actions/organization";
import {QUESTION_SHOW} from "@/store/actions/question";
import {ALERT_SHOW} from "@/store/actions/alert";

export default {
  name: "PrintPage",
  props: ['address', 'email_send_button'],
  computed: {
    ...mapGetters({
      modalAnswer: 'question_answer',
      organization: 'getCurrentOrganization',
      flat: 'getFlat'
    }),
    getComponent() {
      switch (this.current_certificate) {
        case 0:
          return () => import("@/components/flatPrint/certificate_204")
        case 1:
          return () => import("@/components/flatPrint/unemployment_book")
        case 2:
          return () => import("@/components/flatPrint/unemployment_witness")
        case 3:
          return () => import("@/components/flatPrint/indebtedness")
        case 4:
          return () => import("@/components/flatPrint/family_members")
        case 5:
          return () => import("@/components/flatPrint/live_place")
        default:
          return null
      }
    },
    getHeight() {
      if (this.$vuetify.breakpoint.xs) {
        return '100px'
      }

      if (this.$vuetify.breakpoint.sm) {
        return '54px'
      }

      if (this.$vuetify.breakpoint.mdAndUp) {
        return '54px'
      }

      return '54px'
    }
  },
  data() {
    return {
      emailRules: [
        v => !!v || 'Це поле э обов’язковим',
        v => /.+@.+/.test(v) || 'Неправильний email',
      ],
      formValid: false,
      scale: 100,
      current_certificate: null,
      settings: false,
      persons: [],
      person_select: [],
      person: null,
      print_data: {},
      generate_xlsx: null,
      generate_email: null,
      xlsx_loading_status: false,
      email_status: false,
      email_watcher: null,
      items: [
        'Довідка №204',
        'Довідка по безробітті (трудова книжка)',
        'Довідка по безробітті (свідчення сусідів)',
        'Довідка по відсутність заборгованості',
        'Довідка про склад сім’ї',
        'Довідка по місцю проживання'
      ],
      show_email_dialog: false,
      email: '',
    }
  },
  methods: {
    ...mapActions({
      fetchFamily: FETCH_FAMILY_BY_FLAT,
    }),
    closeModal() {
      this.$emit('closeModal')
    },
    openSettings() {
      this.settings = !this.settings
    },
    onGenerateXlsx(payload) {
      this.generate_xlsx = payload
    },
    onGenerateEmail(payload) {
      this.generate_email = payload
    },
    setXlsxLoading(payload) {
      this.xlsx_loading = payload
    },
    setSendingEmailStatus(payload) {
      this.email_status = payload
      this.show_email_dialog = false
    },
    closeSettings() {
      this.settings = false
    },
    print() {
      window.print()
    },
    showStartSendingQuestion() {
      const payload = {
        text: `Підтвердіть відправлення по email`,
        accept_button: true,
        id: 'start_sending_report_by_email_print_page'
      }
      this.show_email_dialog = false
      this.generate_email = false
      this.email = this.flat.email || ''
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    email_dialog_close() {
      this.email = ''
      this.show_email_dialog = false
    },
    email_dialog_send() {
      if (!this.email) {
        this.$store.commit(ALERT_SHOW, {text: 'Вкажіть email', color: 'error'})
        return
      }
      this.formValid = this.$refs.form.validate()
      if (!this.formValid) {
        this.formValid = false
        this.$store.commit(ALERT_SHOW, {
          text: 'Email порожній або не відповідає структурі email адреси',
          color: 'error lighten-1'
        })
        return
      }
      this.generate_email = !this.generate_email
    },
    watch_modal_answer() {
      this.email_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === 'start_sending_report_by_email_print_page') {
                if (payload.answer) {
                  this.show_email_dialog = true
                }
              }
            }
          }
      )
    },
  },
  created() {
    this.watch_modal_answer()
    this.fetchFamily(this.$route.params.person_hash)
        .then(res => {
          if (res) {
            this.persons = this.$store.state.family.family.map((item, idx) => {
              return Object.assign({}, item, {row_num: idx + 1, selected: true})
            })
            this.person_select = this.persons.map(item => {
              if (item.relation_name === 'Власник') {
                this.person = item.person_hash
              }
              return {text: item.full_name, value: item.person_hash}
            })
          }
        })
    this.$store.dispatch(GET_ORGANIZATION_PRINT_DATA)
        .then(
            data => this.print_data = data
        )
  },
  beforeDestroy() {
    if (this.email_watcher) {
      this.email_watcher()
    }
  }
}
</script>

<style scoped lang="scss">

</style>